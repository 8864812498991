@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: min(16px, 2.6vw);
  }
  body, html, #root {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
  }
  
  @media not screen and (hover) {
    * {
      cursor: default !important;
    }
  }

  @keyframes cube-float {
    0% {
      filter: blur(0px);
      transform: scale(1) rotate(-1deg) translate3d(3px, 2px, 0px);
    }
    60% {
      filter: blur(0px);
      transform: scale(0.98) rotate(-2deg);
    }
    90% {
      filter: blur(1px);
      transform: scale(1.01) rotate(-2deg) translate3d(3px, -2px, 0px);
    }
    100% {
      filter: blur(0px);
      transform: scale(1) rotate(-1deg) translate3d(3px, 2px, 0px);
    }
  }

  .animate-cube-float {
    transform-origin: center center;
    animation: cube-float 4s ease-in-out infinite;
  }

  .delay-100 {
    animation-delay: 100ms;
  }

  .delay-500 {
    animation-delay: 500ms;
  }
}